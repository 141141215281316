import { useStaticQuery, graphql } from 'gatsby';

const shopGet = (shop: string) => {
  const shopdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsShopSrph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              title
              information{
                keyname
                value
              }
              shopSrphId
            }
          }
        }
      }`
  )
  const shopdata = shopdataquery.allMicrocmsShopSrph.edges
  return shopdata.filter((item: any) => {
    if (item.node.shopSrphId == shop) {
      return true
    } else {
      return false
    }
  })[0].node
}

export default shopGet