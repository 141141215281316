import { useStaticQuery, graphql } from 'gatsby';
import { format as formatDate, addDays } from 'date-fns';

const menuChoice = (data: Array<any> = [], classification: Array<string> = [], count = 3, skip = 0) => {
  const today = new Date(formatDate(new Date(), 'yyyy/MM/dd'))
  const ary = data.filter((item) => {
    const startdate = item.node.date_range?.start && new Date(formatDate(new Date(item.node.date_range?.start), 'yyyy/MM/dd'))
    const enddate = item.node.date_range?.end && new Date(formatDate(new Date(item.node.date_range?.end), 'yyyy/MM/dd'))

    //区分フィルター
    if (classification.length > 0) {
      if (!item.node.classification.some((el: string) => classification.includes(el))) {
        return false
      }
    }

    //期間フィルタ
    if (item.node.date_range?.start && today.getTime() < startdate.getTime()) {
      return false
    }
    if (item.node.date_range?.end && today.getTime() > enddate.getTime()) {
      return false
    }

    return true
  })
  return ary.slice(skip, count == 0 ? undefined : count)
}

export default menuChoice