import { useStaticQuery, graphql } from 'gatsby';

const menuGet = () => {
  const menudataquery = useStaticQuery(graphql
    `query{
        allMicrocmsMenuSrph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              classification
              title
              subtitle
              eyecatch {
                url
                height
                width
              }
              menu_pdf{
                menu_title
                pdf {
                  url
                }
              }
              date_range {
                start
                end
              }
            }
          }
        }
      }`
  )
  const menudata = menudataquery.allMicrocmsMenuSrph.edges
  return menudata
}

export default menuGet