import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLinkCard,
  CHeroImg,
  CDefinition,
  CBtnList,
  CSliderMedia,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import menuChoice from '../../../utils/menu-choice';
import menuGet from '../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('nanatsumori'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          logo: {
            src: '/assets/images/restaurants/nanatsumori/logo.svg',
            alt: '七つ森',
          },
          title: {
            ja: 'ナナツモリ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/nanatsumori/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/nanatsumori/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: '七つ森',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              食材の味をそのままにたのしむ
              <br />
              匠の技を感じるこだわりの鉄板焼
            </h2>
            <p className="c_sectLead">
              東北・宮城の食材や仙台牛の素材の味を五感でたのしめるメニュー。
              <br />
              目の前で調理される臨場感やシェフとの会話がたのしめるのも鉄板焼ならでは。
              <br />
              とびきり特別な日にご利用ください。
            </p>
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/nanatsumori/img_hero.png',
                alt: '',
              }}
            />
            <CSliderMedia
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori02.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori03.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      素材の味を満喫
                      <br />
                      至高の鉄板焼を支える食材
                    </>
                  ),
                  text: (
                    <>
                      東北・宮城の選り抜きの食材を中心とした
                      <br className="u_pc" />
                      鉄板焼に相応しい数々の食材。
                      <br />
                      仙台牛や鮑をはじめその時の食材を堪能しながら、
                      <br className="u_pc" />
                      東北・宮城ならではの豊かな味わいをおたのしみください。
                    </>
                  ),
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori04.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori05.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori06.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      王道料理を満喫
                      <br />
                      鉄板焼に酔いしれる匠の技
                    </>
                  ),
                  text: (
                    <>
                      食材の旨さを最大限に引き出す熟練の技。
                      <br className="u_pc" />
                      迫力ある焼きと漂う美味しそうな香りに心が躍る。
                      <br />
                      匠の高い技術と強いこだわりが濃縮された
                      <br className="u_pc" />
                      絶品の鉄板焼をおたのしみください。
                    </>
                  ),
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori07.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/nanatsumori/img_nanatsumori08.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      プライベートタイムを満喫
                      <br />
                      ゆっくり贅沢にくつろげる隠れ家
                    </>
                  ),
                  text: (
                    <>
                      奥まった場所にある七つ森はさながら隠れ家。
                      <br className="u_pc" />
                      友人やご家族と一緒に、おひとりでゆっくりと。
                      <br className="u_pc" />
                      落ち着いたくつろげる空間で鉄板焼を
                      <br className="u_pc" />
                      堪能する贅沢な時間をおたのしみください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect u_bgGray u_mbExLarge">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>メニュー</>,
              en: <>MENU</>,
            }}
          />
          <CLinkCard data={menuChoice(menuGet(), ['七つ森 店舗 トップ'], 30)} />
          <CBtnList
            data={[
              {
                label: 'ご予約',
                link: {
                  href: 'https://booking.ebica.jp/webrsv/search/e014024501/12252',
                },
                icon: 'blank',
              },
            ]}
          />
          <ul className="c_noteList u_mtMedium">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{ ja: <>営業時間｜ご利用案内</>, en: <>GUIDE</> }}
            />
            <CDefinition
              exClass="u_mb100 u_mb80_sp"
              data={shopdata.information.map((item: any) => {
                return {
                  title:
                    item.keyname &&
                    item.keyname.split('\n').map((t: string) => (
                      <>
                        {t}
                        <br />
                      </>
                    )),
                  text: (
                    <div
                      className="wysiwyg"
                      dangerouslySetInnerHTML={{
                        __html: `${item.value}`,
                      }}
                    />
                  ),
                };
              })}
            />
          </LWrap>
        </section>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
